import './App.scss';

import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import TopNavBar from "./TopNavBar";

function App() {
    return (
        <div className="App">
            {/*<Header />*/}
            <TopNavBar />
            <Content />
            <Footer />
        </div>
    );
}

export default App;
