import React from "react";

class NavPage extends React.Component{
    render() {
        return <div>
            <div className={"navCategory"}>
                搜索网站
                <ul>
                    <li><a href={"https://www.baidu.com"}>百度</a></li>
                    <li><a href={"https://cn.bing.com/"}>必应</a></li>
                </ul>

            </div>
            <div className={"navCategory"}>
                门户网站
                <ul>
                    <li><a href={"https://www.weibo.com"}>新浪微博</a></li>
                    <li><a href={"https://www.163.com/"}>网易</a></li>
                    <li><a href={"https://www.tecent.com/"}>腾讯</a></li>
                </ul>
            </div>

            <div className={"navCategory"}>
                React组件
                <ul>
                    <li><a href={"https://ant.design/index-cn"}>Ant Design</a></li>
                    <li><a href={"https://material-ui.com/zh/"}>Material UI</a></li>
                </ul>
            </div>
        </div>;
    }
}

export default NavPage;