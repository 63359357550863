import React from "react";

class ToolsPage extends React.Component{
    render() {
        return <div>
            <div className={"navCategory"}>
                <ul>
                    <li><a href={"https://cli.im/"}>二维码生成</a></li>
                    <li><a href={"https://tool.lu/timestamp/"}>时间戳转换</a></li>
                    <li><a href={"http://tool.chacuo.net/cryptaes"}>AES加解密</a></li>
                </ul>
            </div>
        </div>;
    }
}

export default ToolsPage;