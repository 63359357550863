import React from "react";

class Header extends React.Component{
    render() {
        return <div className={"header"}>
            <h1>
                番茄ABC
            </h1>
        </div>;
    }
}

export default Header;