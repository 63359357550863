import React from "react";
import {withRouter} from "react-router";
import {Menu} from "antd";
import { RouteComponentProps } from "react-router-dom";

interface ISection{
    id:number;
}

class TopNavBar extends React.Component<ISection & RouteComponentProps> {
    render() {
        return <div className={"topNavBar"}>
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
                <Menu.Item key="1" onClick={(e) => this.openLink("/")}>首页</Menu.Item>
                <Menu.Item key="2" onClick={(e) => this.openLink("/nav")}>导航</Menu.Item>
                <Menu.Item key="3" onClick={(e) => this.openLink("/tools")}>工具</Menu.Item>
                <Menu.Item key="4" onClick={(e) => this.openLink("/about")}>关于</Menu.Item>
            </Menu>
        </div>;
    }

    openLink(link:string){
        this.props.history.push(link);
    }
}

export default withRouter(TopNavBar);