import React from "react";
import TopNavBar from "./TopNavBar";
import {Redirect, Route} from "react-router-dom";
import App from "./App";
import NavPage from "./pages/NavPage";
import ToolsPage from "./pages/ToolsPage";
import AboutPage from "./pages/AboutPage";
import HomePage from "./pages/HomePage";

class Content extends React.Component{
    render() {
        return <main className={"main"}>
            <switch>
                <Route exact path={"/"} component={HomePage}/>
                <Route path={"/nav"} component={NavPage}/>
                <Route path={"/tools"} component={ToolsPage}/>
                <Route path={"/about"} component={AboutPage}/>
            </switch>
        </main>;
    }
}

export default Content;