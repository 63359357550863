import React from "react";
import {Empty, Skeleton} from "antd";

class AboutPage extends React.Component{
    render() {
        return <div>
            <Skeleton avatar active paragraph={{rows: 10}}/>
        </div>;
    }
}

export default AboutPage;