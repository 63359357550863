import React from "react";
import {Calendar, Carousel, Image} from "antd";

class HomePage extends React.Component{
    render() {
        return <div>
            <Carousel autoplay className={"pager"}>
                <Image
                    src={"https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fpic1.win4000.com%2Fwallpaper%2F2020-07-08%2F5f053d0bb5cc9.jpg&refer=http%3A%2F%2Fpic1.win4000.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1631348794&t=7b353396d1f7a0f242d4f77da7a7e4d6"}
                />
                <Image
                    src={"https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.pconline.com.cn%2Fimages%2Fupload%2Fupc%2Ftx%2Fwallpaper%2F1309%2F05%2Fc4%2F25277592_1378345913464.jpg&refer=http%3A%2F%2Fimg.pconline.com.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1631348794&t=6a4d6964d66b2d39713175955b1b9c40"}
                />
                <Image
                    src={"https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg9.51tietu.net%2Fpic%2F2019-091300%2F5r43nuja5wa5r43nuja5wa.jpg&refer=http%3A%2F%2Fimg9.51tietu.net&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1631348794&t=94e6162694ed191ed22965b45953d558"}
                />
                <Image
                    src={"https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.pconline.com.cn%2Fimages%2Fupload%2Fupc%2Ftx%2Fwallpaper%2F1306%2F14%2Fc3%2F22068471_1371197032005.jpg&refer=http%3A%2F%2Fimg.pconline.com.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1631348794&t=18910e4380508a12cd031f9d2385853a"}
                />

            </Carousel>
        </div>;
    }
}

export default HomePage;