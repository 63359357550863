import React from "react";
import Header from "./Header";

class Footer extends React.Component{
    render() {
        return <div className={"footer"}>
            <span className={"copyright"}>Copyright @ 番茄技术ABC </span>
            <a href={"https://beian.miit.gov.cn/"} className={"copyright"}>备案号：粤ICP备2021103027号</a>
        </div>;
    }
}

export default Footer;